.sidebar-wrap {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.1803921569);
  background-color: #f5f5f5;
  color: black;
  padding: 10px;
  transition: 0.35s;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 3;

  .logo-wrap {
    color: black;
    font-size: 35px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .logo-wrap span {
    font-size: 18px;
  }

  .logo-wrap .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 40px;
  }

  .nav {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    flex-wrap: nowrap;
  }

  // .nav::-webkit-scrollbar-track {
  //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //     border-radius: 20px;
  //     background-color: #f5f5f5;
  // }

  // .nav::-webkit-scrollbar {
  // width: 5px;
  // background-color: #f5f5f5;
  // border-radius: 20px;
  // }

  // .nav::-webkit-scrollbar-thumb {
  //     border-radius: 20px;
  //     -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  //     background-color: #9b9b9b;
  // }

  .nav li {
    margin-top: 5px;
  }

  .nav li .nav-link {
    color: black;
    padding: 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 20px;
    // .sidebar-icon {
    //   font-size: 1.3rem ;
    // }

    // &:hover {
    //   .sidebar-icon {
    //     font-size: 1.5rem;
    //   }
    // }
  }

  .nav li .nav-link .icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    min-width: 40px;
  }

  .nav li .nav-link:not(#sidebar-menu) {
    span {
      font-size: 16px;
    }
  }

  .nav #sidebar-menu {
    margin-top: 5px;
  }

  .dropdown-wrap {
    display: flex;
    align-items: center;
    color: black;
    gap: 15px;
    font-size: 16px;
  }

  .dropdown-wrap .icon-wrap {
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.sidebar-wrap:hover {
  .logo-wrap span {
    display: flex;
  }

  .nav li .nav-link span {
    display: flex;
  }

  .dropdown-wrap strong {
    display: flex;
  }

  .dropdown-wrap::after {
    display: inline-block;
  }

  .dropdown-wrap {
    justify-content: flex-start;
  }
}

.sidebar-collapsed {
  width: 60px;
  min-width: 0px;
}

.sidebar-expanded {
  width: calc(100% + 20px);
  min-width: 200px;
}
