:root {
  --theme-primary: #AD896E;
  // --theme-secondary: #d0b8ac;
}

.loading-container {
  path[data-testid="infinity-spin-path-1"] {
    stroke: var(--theme-primary) !important;
  }
}

.theme-custom,
.ReactModalPortal,
#popup-root {
  .btn-primary {
    background: var(--theme-primary) !important;
    border: 1px solid var(--theme-primary) !important;
    color: white !important;

    &:hover {
      filter: brightness(90%);
    }

    &:active {
      filter: brightness(90%);
    }
  }

  .btn-outline-primary {
    background: transparent !important;
    color: var(--theme-primary) !important;
    border: 1px solid var(--theme-primary) !important;

    &:hover {
      background: var(--theme-primary) !important;
      filter: brightness(90%);
      color: white !important;
    }

    &:active {
      background: var(--theme-primary) !important;
      filter: brightness(90%);
      color: white !important;
    }
  }

  .bg-primary {
    background: var(--theme-primary) !important;
  }

  .text-primary {
    color: var(--theme-primary) !important;
  }

  // .sidebar-wrap .nav-link.active {
  //   background: var(--theme-primary) !important;
  //   color: white !important;
  // }

  .navbar {
    .nav-link.active {
      color: var(--theme-primary) !important;
    }
  }

  .sidebar-wrap {
    .nav li .nav-link:not(#sidebar-menu) {
      &.active {
        background-color: var(--theme-primary) !important;
        color: white !important;
      }

      &:hover {
        background-color: var(--theme-primary) !important;
        color: white !important;
      }
    }
  }

  .btn-check:checked + .btn,
  :not(.btn-check) + .btn:active,
  .btn:first-child:active,
  .btn.active,
  .btn.show {
    border-color: var(--theme-primary) !important;
  }

  .form-control:focus {
    border-color: var(--theme-primary) !important;
  }

  .custom-overflow,
  .quill .ql-editor {
    &::-webkit-scrollbar-thumb {
      background-color: var(--theme-primary) !important;
    }
  }
}
