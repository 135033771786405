// Body
$body-bg: #ffffff;
$primary: #AD896E;
$secondary: #d0b8ac;

// Loader
$loader-bg: #ffffff59;
$loader-spinner-bg: #8a8a8a;
// $loader-spinner: #ac988e;

// Full Loader
$full-loader-bg: #ffffff;

// Typography
// $font-family-sans-serif: "Nunito", sans-serif;
// $font-family-sans-serif: "Comic Sans MS", "Comic Sans", cursive;

$font-size-base: 0.9rem;
$line-height-base: 1.6;

$btn-padding-x: 0.6rem;
$btn-padding-y: 0.25rem;


// $a: #9c8a81;
// $a: #ac988e;
// $a: #d0b8ac;
// $a: #e2c8ba;
// $a: #f3d8c7;
// $a: #f5e5db;
// $a: #f8f5f2;
// $a: #ededed;
